import React, {useContext, useEffect, useState} from 'react';
import Header from '../components/Header';
import OleCheckbox from '../components/OleCheckbox';
import FormFlyware from '../components/FormFlyware';
import LinkOptionContext from '../contexts/LinkOptionContext';
import { languageMessages } from 'traductor/PaymentLink';
import { useLocation } from 'react-router-dom';
import { LinkResponse, TransformedData, transformLinkResponse } from 'services/link/types';
import { getHashInfo } from 'services/link/linkService';
import Dialog from '../components/OleDialog';
import OlePopupInformation from '../components/OlePopupInformation';
import Skeleton from '../components/Skeleton';
import PageConfirmation from './PageConfirmation';
import { checkout } from 'services/checkout/checkoutService';
import { CheckoutRequest } from 'services/checkout/types';
import { CardUpdateRequest } from 'services/card/types';
import { updateCard } from 'services/card/CardService';
import LanguageContext from 'contexts/LanguageContext';
import FormPersonalData from 'components/FormPersonalData';
import '../styles/PaymentLink.css'
import Layout from 'Layout';
import { REACT_APP_TIME_REFRESH } from 'config';

function PaymentLink({language}: {language: string}) {
    const [token, setToken] = useState('');
    // eslint-disable-next-line
    const [selectedLinkOption, setSelectedLinkOption] = useState(localStorage.getItem('selectedLinkOption') || 'payment');
    const location = useLocation();
    const [sessionLoaded, setSessionLoaded] = useState(false);
    const [sessionData, setSessionData] = useState<LinkResponse | null>(null);
    const [objectData, setObjectData] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenInformation, setIsOpenInformation] = useState(false);
    
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState<string>();
    const [result, setResult] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [retry, setRetry] = useState(-1);
    const [stepActive, setStepActive] = useState(true);
    const [informationPayment, setInformationPayment] = useState<Map<string, string>>(new Map());
    const [informationUpdate, setInformationUpdate] = useState<Map<string, string>>(new Map());
    // eslint-disable-next-line
    const { changeLanguage } = useContext(LanguageContext);
    const RECURRENT_ALWAYS = true
    const [INSURED_TEXT, setInsuredText] = useState(languageMessages(language, 'insured'))
    const [POLICY_OWNER_TEXT, setPolicyOwnerText] = useState(languageMessages(language, 'policyOwner'))
    const [POLICY_NUMBER_TEXT, setPolicyNumberText] = useState(languageMessages(language, 'policyNumber'))
    // eslint-disable-next-line
    const [PLAN, setPlan] = useState(languageMessages(language, 'plan'))
    // eslint-disable-next-line
    const [TERM, setTerm] = useState(languageMessages(language, 'term'))
    // eslint-disable-next-line
    const [PREMIUM, setPremium] = useState(languageMessages(language, 'premium'))
    const [isVantage, setIsVantage] = useState(false);

    const responseflywire = async(dato:any) => {
        if (dato.success) {
            console.log('flywire callback', dato);
            setRetry(-1);
            setSuccess(true);
            setResult(dato?.args);
            setStatus(dato?.args.status)
            try {
                const ids = objectData.dues.filter((due: any) => due.checked).map((due: any) => { return due.id; });
                if (parseFloat(dato?.args.amount) > 0) {
                    if (dato?.args.paymentMethod === 'card' || dato?.args.paymentMethod === 'credit_card' || dato?.args.paymentMethod === 'recurring') {
                        let recurrentOption = objectData.recurrent;
                        if (dato?.args.token !== '') {
                            recurrentOption = true;
                        }
                        const checkoutData:CheckoutRequest = {
                        application_id: objectData.idApplication,
                        card: {
                            "brand": dato?.args.brand,
                            "digits": dato?.args.digits,
                            "expirationMonth": dato?.args.expirationMonth,
                            "expirationYear": dato?.args.expirationYear,
                            "sig": dato?.args.sig,
                            "token": dato?.args.token,
                            "type": dato?.args.type,
                            "recurrent": recurrentOption,
                        },
                        "ids": ids,
                        "reference": dato?.args.reference,
                        "amount_from": dato?.args.payerAmount,
                        "amount_to": parseFloat(dato?.args.amount),
                        "currency_from": dato?.args.payerAmountCurrency,
                        "currency_to": dato?.args.amountCurrency,
                        "paymentMethod": dato?.args.paymentMethod,            
                        "status": dato?.args.status,
                        payer: {
                            first_name: objectData.firstName,
                            last_name: objectData.lastName,
                            middle_name: '',
                            address1: objectData.address,
                            address2: '',
                            city: objectData.city || objectData.cityForFlywire,
                            state: objectData.state,
                            zip: objectData.zipCode,
                            country: objectData.country,
                            phone: objectData.phone,
                            email: objectData.email,
                            paymaster: objectData.payingForPolicy,
                            paymaster_relationship: objectData.relationship,
                        },
                        };
                        console.log(`checkout(${objectData.recipientCode})`, checkoutData);
                        const res = await checkout(checkoutData, objectData.recipientCode);
                        if (res.success) {
                            console.log('checkout (response)', 'success');
                        } else {
                            console.error('checkout (response)', res.error);
                        }
                    } else {
                        console.warn('checkout', 'paymentMethod not supported');
                    }
                } else {
                    const cardData:CardUpdateRequest = {
                      brand: dato?.args.brand,
                      digits: dato?.args.digits,
                      expirationMonth: dato?.args.expirationMonth,
                      expirationYear: dato?.args.expirationYear,
                      sig: dato?.args.sig,
                      token: dato?.args.token,
                      type: dato?.args.type,
                      recurrent: RECURRENT_ALWAYS,
                      payer: {
                        first_name: objectData.firstName,
                        last_name: objectData.lastName,
                        middle_name: '',
                        address1: objectData.address,
                        address2: '',
                        city: objectData.city || objectData.cityForFlywire,
                        state: objectData.state,
                        zip: objectData.zipCode,
                        country: objectData.country,
                        phone: objectData.phone,
                        email: objectData.email,
                        paymaster: objectData.payingForPolicy,
                        paymaster_relationship: objectData.relationship,
                      }
                    };
                    console.log(`updateCard(${objectData.recipientCode})`, cardData);
                    const res = await updateCard(cardData, objectData.recipientCode, token);
                    if (res.success) {
                        console.log('updateCard(response)', res);
                    } else {
                        console.error('updateCard(response)', res.error);
                    }
                }
            } catch (error) {
                setIsOpen(true);
                console.error('flywire callback', error);
            }
        }
    }

    useEffect(() => {
        const newData: Partial<TransformedData> = { language: language };
        setObjectData((prevData: any) => ({ ...prevData, ...newData }));
        const A1 = languageMessages(language, 'insured')
        //const A2 = languageMessages(language, 'policyOwner')
        const A3 = languageMessages(language, 'policyNumber')
        const A5 = languageMessages(language, 'term')
        const A6 = languageMessages(language, 'premium')
        const A7 = languageMessages(language, "frequency")
        setInsuredText(languageMessages(language, 'insured'))
        setPolicyOwnerText(languageMessages(language, 'policyOwner'))
        setPolicyNumberText(languageMessages(language, 'policyNumber'))
        setPlan(languageMessages(language, 'plan'))
        setTerm(languageMessages(language, 'term'))
        setPremium(languageMessages(language, 'premium'))
        setInformationPayment(new Map(Object.entries(
            {
                [A1]: sessionData?.first_name + ' ' + sessionData?.last_name,
                //[A2]: sessionData?.first_name + ' ' + sessionData?.last_name,
                [A3]: sessionData?.id_application || '',
            }
        )));
        setInformationUpdate(new Map(Object.entries(
            {
                [A1]: sessionData?.first_name + ' ' + sessionData?.last_name || '',
                //[A2]: sessionData?.first_name + ' ' + sessionData?.last_name || '',
                [A3]: sessionData?.id_application || '',
                [A5]: sessionData?.term + ' ' + A7[sessionData?.frequency || ''] || '',
                [A6]: sessionData?.currency + ' $' + sessionData?.payments[0].amount || '',
            }
        )));
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        document.documentElement.style.setProperty('--fwpDisplayForm', 'none');
        document.documentElement.style.setProperty('--fwpDisplayData', 'block');

        const queryParams = new URLSearchParams(location.search);
        const sessionToken = queryParams.get('subscription') ?? '';
        const originVantage = queryParams.get('origin') ?? '';
        
        setToken(sessionToken);
        setIsVantage(originVantage === 'app-mobile');
        if (!localStorage.getItem('selectedLinkOption')) {
            localStorage.setItem('selectedLinkOption', 'payment');
            changeOption('payment');
        }
    
        if (!sessionToken || sessionLoaded) {
          if (!sessionToken) {
            console.error('session', languageMessages(language, 'errorMessages')['payment'].subtitle);
            setError({
                title: languageMessages(language, 'errorMessages')['payment'].title,
                subtitle: languageMessages(language, 'errorMessages')['payment'].subtitle,
                buttonText: languageMessages(language, 'errorMessages')['payment'].buttonText
            });
            setIsOpen(true);
          }
          return;
        }

        const fetchData = async () => {
            try {
                const session: LinkResponse = await getHashInfo(sessionToken);
                if (session.payments === undefined || session.payments.length === 0) {
                    setError({
                        title: languageMessages(language, 'errorMessages')['notPendingPayments'].title,
                        subtitle: languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle,
                        buttonText: languageMessages(language, 'errorMessages')['notPendingPayments'].buttonText
                    });
                    setIsOpen(true);
                    console.warn('session', languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle);
                    return;
                }
                setSessionData(session);
                setObjectData(transformLinkResponse(session));
                const languageMap: { [key: string]: string } = {
                    EN: 'en',
                    US: 'en',
                    SP: 'es',
                    PT: 'pt',
                    ES: 'es',
                };
                changeLanguage(languageMap[session.language]);
                const A1 = languageMessages(languageMap[session.language], 'insured')
                //const A2 = languageMessages(languageMap[session.language], 'policyOwner')
                const A3 = languageMessages(languageMap[session.language], 'policyNumber')
                const A5 = languageMessages(languageMap[session.language], 'term')
                const A6 = languageMessages(languageMap[session.language], 'premium')
                const A7 = languageMessages(languageMap[session.language], "frequency")
                setInsuredText(languageMessages(languageMap[session.language], 'insured'))
                setPolicyOwnerText(languageMessages(languageMap[session.language], 'policyOwner'))
                setPolicyNumberText(languageMessages(languageMap[session.language], 'policyNumber'))
                setPlan(languageMessages(languageMap[session.language], 'plan'))
                setTerm(languageMessages(languageMap[session.language], 'term'))
                setPremium(languageMessages(languageMap[session.language], 'premium'))
                setInformationPayment(new Map(Object.entries(
                    {
                        [A1]: session?.first_name + ' ' + session?.last_name,
                        //[A2]: session?.first_name + ' ' + session?.last_name,
                        [A3]: session?.id_application || '',
                    }
                )));
                setInformationUpdate(new Map(Object.entries(
                    {
                        [A1]: session?.first_name + ' ' + session?.last_name || '',
                        //[A2]: session?.first_name + ' ' + session?.last_name || '',
                        [A3]: session?.id_application || '',
                        [A5]: session?.term + ' ' + A7[session?.frequency || ''] || '',
                        [A6]: session?.currency + ' $' + session?.payments[0].amount || '',
                    }
                )));
            } catch (error) {
                console.error('session', JSON.stringify(error));
                setError({
                    title: languageMessages(language, 'errorMessages')['session'].title,
                    subtitle: languageMessages(language, 'errorMessages')['session'].subtitle,
                    buttonText: languageMessages(language, 'errorMessages')['session'].buttonText
                });
                setIsOpen(true);
                //console.error('Error getting session:', error);
            } finally {
                setSessionLoaded(true);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [sessionData, objectData, location.search, sessionLoaded, language, changeLanguage, INSURED_TEXT, POLICY_OWNER_TEXT, POLICY_NUMBER_TEXT]);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(location.search);
            const sessionToken = queryParams.get('subscription') ?? '';
            const session: LinkResponse = await getHashInfo(sessionToken);
            if (session.payments === undefined || session.payments.length === 0) {
                setError({
                    title: languageMessages(language, 'errorMessages')['notPendingPayments'].title,
                    subtitle: languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle,
                    buttonText: languageMessages(language, 'errorMessages')['notPendingPayments'].buttonText
                });
                setIsOpen(true);
                console.warn('session', languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle);
                return;
            }
            setSessionData(session);
            setObjectData(transformLinkResponse(session));
        }
        fetchData();
    // eslint-disable-next-line
    }, [success]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const option = queryParams.get('option') ?? '';
        if (option === 'update') {
            setSelectedLinkOption('update');
            localStorage.setItem('selectedLinkOption', 'update');
        }
        // eslint-disable-next-line
    }, []);

    const handleCheckboxChange = (id: number) => {
        const updatedDues = objectData?.dues.map((due:any, index:number) => {
            if (index > 0 && due.id === id) {
                due.checked = !due.checked;
                if (index < objectData.dues.length - 1) {
                    objectData.dues[index + 1].disabled = !due.checked;
                    objectData.dues[index + 1].checked = false;
                    for (let i = index + 2; i < objectData.dues.length; i++) {
                        objectData.dues[i].checked = false;
                        objectData.dues[i].disabled = true;
                    }
                }
            }
            return due;
        });
        const total = updatedDues?.reduce((acc:any, due:any) => {
            if (due.checked) {
                acc += parseFloat(due.premium.toString());
            }
            return acc;
        }, 0);
        let roundedTotal = parseFloat(total.toFixed(2));
        const ids = objectData.dues.filter((due: any) => due.checked).map((due: any) => { return due.id; });
        const newData: Partial<TransformedData> = { dues: updatedDues ?? [], amount: roundedTotal, paymentIds: ids };
        setObjectData((prevData: any) => ({ ...prevData, ...newData }));
    };

    const changeOption = (newOption: React.SetStateAction<string>) => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('option')) {
            queryParams.delete('option');
            window.history.replaceState({}, '', `${location.pathname}?${queryParams}`);
        }
        
        localStorage.setItem('selectedLinkOption', newOption.toString());
        window.location.reload();
    };

    const callFormPayment = () => {
        //document.documentElement.style.setProperty('--fwpDisplayFormMobile', 'block');
        //document.documentElement.style.setProperty('--fwpDisplayDataMobile', 'none');
        let _object = objectData;
        //_object.recurrent = checked;
        setObjectData(_object);
        //console.log('session', objectData);
        setRetry(retry + 1);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    
    const handleButtonClick = () => {
        window.location.reload();
    };

    const handleButtonContinue = () => {
        setStepActive(false);
    }

    const handleButtonReturn = () => {
        if (isVantage) {
            try {
                //@ts-ignore
                window.ReactNativeWebView.postMessage("session-back");
            } catch (error) {
                console.error('session-back', error);
            }
            return;
        }
        //window.open(objectData.redirectUrl, '_self');
    }

    const handleBackStep = () => {
        setStepActive(true);
    }

    const showInformation = () => {
        setIsOpenInformation(true);
    }

    const onInactive = () => {
        console.warn('activity', 'User inactive');
        window.location.href = '/';
    }

    return (
        <Layout inactivityTime={Number.parseInt(REACT_APP_TIME_REFRESH)} onInactive={onInactive}>
        <LinkOptionContext.Provider value={{ option: selectedLinkOption, changeOption }}>
            {isOpen && (
            <Dialog
                title={error===null ? '' : error.title}
                subtitle={error===null ? '' : error.subtitle}
                buttonText={error===null ? '' : error.buttonText}
                onClose={handleClose}
                onButtonClick={handleButtonClick}
                showClose={false}
                />
            )}
            {isOpenInformation && (
            <OlePopupInformation
                information={selectedLinkOption === 'payment' ? informationPayment : informationUpdate}
                title={languageMessages(language, 'policyInformation')}
                onClose={() => setIsOpenInformation(false)}
            />
            )}
            {sessionLoaded ? (
            <div className='app'>
                <Header paymentType='link' language={language} showLogo={true} />
                <div className='payment-header-link' style={selectedLinkOption === 'payment' && !success ? {display : 'block'} : {display: 'none'}}>
                    <div className=''>
                        <div className='return' style={isVantage ? {display: 'block'}: {display: 'none'}}>
                            <div>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleButtonReturn}>
                                    <circle cx="15" cy="15" r="15" transform="rotate(-180 15 15)" fill="white" fill-opacity="0.5"/>
                                    <path d="M17.594 20.1006C17.6834 20.0114 17.7543 19.9055 17.8027 19.7888C17.8511 19.6722 17.876 19.5471 17.876 19.4208C17.876 19.2945 17.8511 19.1695 17.8027 19.0528C17.7543 18.9362 17.6834 18.8302 17.594 18.741L13.8525 14.9996L17.594 11.2581C17.7743 11.0778 17.8756 10.8333 17.8756 10.5783C17.8756 10.3233 17.7743 10.0788 17.594 9.89849C17.4137 9.71819 17.1691 9.61691 16.9142 9.61691C16.6592 9.61691 16.4146 9.71819 16.2343 9.89849L11.8083 14.3246C11.7189 14.4138 11.6479 14.5197 11.5996 14.6364C11.5512 14.753 11.5263 14.8781 11.5263 15.0044C11.5263 15.1307 11.5512 15.2557 11.5996 15.3724C11.6479 15.489 11.7189 15.595 11.8083 15.6842L16.2343 20.1103C16.6008 20.4767 17.2179 20.4767 17.594 20.1006Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='step-1' style={ stepActive ? {display: 'block'} : {display: 'none'}}>
                                <svg width="115" height="30" viewBox="0 0 115 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="31" y1="14.5" x2="84" y2="14.5" stroke="white" stroke-opacity="0.5"/>
                                    <circle cx="16" cy="15" r="15" transform="rotate(-180 16 15)" fill="#00D090"/>
                                    <path d="M12.1016 8.4H17.5376V21H14.6216V10.74H12.1016V8.4Z" fill="white"/>
                                    <circle cx="100" cy="15" r="15" transform="rotate(-180 100 15)" fill="white" fill-opacity="0.5"/>
                                    <path d="M98.8894 18.624H104.307V21H94.7854V19.11L99.6454 14.52C100.761 13.458 100.959 12.81 100.959 12.198C100.959 11.208 100.275 10.632 98.9434 10.632C97.8634 10.632 96.9454 11.046 96.3334 11.892L94.2094 10.524C95.1814 9.102 96.9454 8.184 99.2134 8.184C102.021 8.184 103.893 9.624 103.893 11.91C103.893 13.134 103.551 14.25 101.787 15.888L98.8894 18.624Z" fill="#5B667A"/>
                                </svg>
                            </div>
                            <div className='step-2' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                                <svg width="114" height="30" viewBox="0 0 114 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleBackStep}>
                                    <line x1="30" y1="14.5" x2="83" y2="14.5" stroke="white"/>
                                    <circle cx="15" cy="15" r="15" transform="rotate(-180 15 15)" fill="#00D090"/>
                                    <path d="M10 15.5L13.375 19L19 12" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <circle cx="99" cy="15" r="15" transform="rotate(-180 99 15)" fill="#00D090"/>
                                    <path d="M97.8894 18.624H103.307V21H93.7854V19.11L98.6454 14.52C99.7614 13.458 99.9594 12.81 99.9594 12.198C99.9594 11.208 99.2754 10.632 97.9434 10.632C96.8634 10.632 95.9454 11.046 95.3334 11.892L93.2094 10.524C94.1814 9.102 95.9454 8.184 98.2134 8.184C101.021 8.184 102.893 9.624 102.893 11.91C102.893 13.134 102.551 14.25 100.787 15.888L97.8894 18.624Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <span className='title' style={ stepActive ? {display: 'block'} : {display: 'none'}}>{languageMessages(language, 'titleStep1')}</span>
                    <span className='title' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>{languageMessages(language, 'titleStep2')}</span>
                    <div className='paymentInfo' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                        <div className='description'>{languageMessages(language, "totalPayment")}</div>
                        <div className='amount'> {objectData?.currency} {objectData?.symbol}{objectData?.amount}<span></span></div>
                    </div>
                </div>
                <div className = { selectedLinkOption === 'payment' ? 'information-t1' : 'information-t2' } style={success ? {display: 'none'} : {}}>
                    <div style={!stepActive ? {display: 'none'} : {display: 'block'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.714 14.2855V8.57124H7.85686V9.99981H9.28544V14.2855H7.14258V15.7141H12.8569V14.2855H10.714ZM9.99972 4.28552C9.78781 4.28552 9.58066 4.34836 9.40447 4.46609C9.22827 4.58382 9.09094 4.75115 9.00985 4.94693C8.92876 5.14271 8.90754 5.35814 8.94888 5.56598C8.99022 5.77381 9.09226 5.96472 9.24211 6.11457C9.39195 6.26441 9.58286 6.36645 9.7907 6.40779C9.99853 6.44913 10.214 6.42792 10.4097 6.34682C10.6055 6.26573 10.7729 6.1284 10.8906 5.9522C11.0083 5.77601 11.0711 5.56886 11.0711 5.35695C11.0711 5.07279 10.9583 4.80027 10.7573 4.59934C10.5564 4.3984 10.2839 4.28552 9.99972 4.28552Z" fill="#00BBDD"/>
                            <path d="M10 20C8.02219 20 6.08879 19.4135 4.4443 18.3147C2.79981 17.2159 1.51809 15.6541 0.761209 13.8268C0.00433283 11.9996 -0.1937 9.98891 0.192152 8.0491C0.578004 6.10929 1.53041 4.32746 2.92894 2.92894C4.32746 1.53041 6.10929 0.578004 8.0491 0.192152C9.98891 -0.1937 11.9996 0.00433283 13.8268 0.761209C15.6541 1.51809 17.2159 2.79981 18.3147 4.4443C19.4135 6.08879 20 8.02219 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20ZM10 1.42858C8.30473 1.42858 6.64754 1.93128 5.23797 2.87312C3.82841 3.81496 2.72979 5.15364 2.08104 6.71986C1.43229 8.28608 1.26254 10.0095 1.59327 11.6722C1.924 13.3349 2.74035 14.8622 3.93909 16.0609C5.13782 17.2597 6.66511 18.076 8.3278 18.4067C9.99049 18.7375 11.7139 18.5677 13.2801 17.919C14.8464 17.2702 16.185 16.1716 17.1269 14.762C18.0687 13.3525 18.5714 11.6953 18.5714 10C18.5714 7.72672 17.6684 5.54654 16.0609 3.93909C14.4535 2.33163 12.2733 1.42858 10 1.42858Z" fill="#00BBDD"/>
                        </svg>
                    </div>
                    <div  style={!stepActive ? {display: 'none'} : {display: 'block'}} className='click' onClick={showInformation}>&nbsp;&nbsp;{languageMessages(language, 'policyInformation')}</div>
                </div>
                <div className='payment-body-link' style={!success ? { display: 'flex' } : { display: 'none' }}>
                    <div className={selectedLinkOption === 'payment' ? 'payment-body-table' : 'payment-body-table-update'} style={ stepActive ? {display: 'block'} : {display: 'none'}}>
                        <div className='payment-data-link'>
                            <div className='title' style = {selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                <span style={{ fontWeight: 'bold' }}><strong>{languageMessages(language, 'policyInformation')}</strong></span>
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'insured')}</span> {objectData?.insured}
                            </div>
                            {/*<div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyOwner')}</span> {objectData?.policyOwner}
                            </div>*/}
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyNumber')}</span> {objectData?.policyNumber}
                            </div>
                            {/*<div className='row' style={selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'plan')}</span> {objectData?.plan}
                            </div>*/}
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'term')}</span> {objectData?.term}
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'period')[objectData?.paymentFrequency]}</span> {objectData?.premium}
                            </div>
                            {/*<div className='row' style={selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'paymentFrequency')}:</span> { languageMessages(language, 'frequency')[objectData?.paymentFrequency] }
                            </div>*/}
                        </div>
                    </div>
                    <div className='payment-form-link' style={ stepActive ? {display: 'flex'} : {display: 'none'}}>                        
                        <div className='payment-quotes-link' style={selectedLinkOption === 'update' ? { display: 'none' } : { display: 'block' }}>
                            <div className='payment-data-link'>
                                <div className='title' style = {selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                    <span style={{ fontWeight: 'bold' }}><strong>{languageMessages(language, 'policyInformation')}</strong></span>
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'insured')} </span> {objectData?.insured}
                                </div>
                                {/*<div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyOwner')} </span> {objectData?.policyOwner}
                                </div>*/}
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyNumber')} </span> {objectData?.policyNumber}
                                </div>
                                {/*<div className='row' style={selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'plan')}:</span> {objectData?.plan}
                                </div>*/}
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'term')} </span> {objectData?.term} {languageMessages(language, 'frequency')[objectData?.paymentFrequency]}
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{ languageMessages(language, 'period')[objectData?.paymentFrequency] }:</span> {objectData?.premium}
                                </div>
                                {/*<div className='row' style={selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'paymentFrequency')}:</span> { languageMessages(language, 'frequency')[objectData?.paymentFrequency] }
                                </div>*/}
                            </div>
                            <div className='table'>
                                <table cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {/*<th>{languageMessages(language, 'tableTitle1')}</th>*/}
                                            <th>{languageMessages(language, 'tableTitle2')}</th>
                                            <th>{languageMessages(language, 'tableTitle3')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objectData?.dues && (
                                            objectData?.dues.map((row:any) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <OleCheckbox id={row.id} checked={row.checked} onChange={handleCheckboxChange} disabled={row.disabled} text='' />
                                                    </td>
                                                    {/*<td>{row.period}</td>*/}
                                                    <td>{row.dueDate}</td>
                                                    <td style={row.withDelay ? { color: '#D00', fontWeight: 'bold' } : { color: '#00D090', fontWeight: 'bold' }}>{row.currency} {row.symbol}{row.premium}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {/*<div className='flag' style={selectedLinkOption === 'update' ? { display: 'none' } : { display: 'flex' }}>
                                <div><OleToggleSwitch id={0} text={languageMessages(language, 'recurringPaymentsAuthorization')} checked={isRecurring} onChange={handleCheckboxRecurringChange}/></div>
                                            </div>*/}
                            {/*<div className='sb'><div className='button' onClick={callFormPayment}>{languageMessages(language, 'continueButton')}</div></div>*/}
                            {/*<div className='avise' style={selectedLinkOption === 'update' ? { display: 'none' } : { display: 'block' }}>{languageMessages(language, 'infoUsageNotice')}</div>*/}
                            <div className='confirmation_continue'>
                                <span className='title'>{languageMessages(language, 'totalPayment')}</span>
                                <span className='amount'>{(objectData?.currency + ' ' + objectData?.symbol + objectData?.amount).replaceAll('undefined', '')}</span>
                                <div className='button' onClick={handleButtonContinue}>{languageMessages(language, 'continueButton')}</div>
                            </div>
                        </div>
                        <div style={selectedLinkOption === 'update' ? { display: 'block' } : { display: 'none' }}>
                            {/*<div className='title'>{languageMessages(language, "payerInformation")}</div>*/}
                            {objectData && <FormPersonalData objectData={objectData} onClick={callFormPayment} language={language} buttonText={selectedLinkOption === 'payment' ? languageMessages(language, "payNowAprroved") : languageMessages(language, "updateCard")} showRecurrent = {false} />}
                            <div className='authorize'>
                                <div className='message'>{languageMessages(language, "authorizeMessage")}</div>
                            </div>
                        </div>
                    </div>
                    <div className='data' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                        {objectData && <FormPersonalData objectData={objectData} onClick={callFormPayment} language={language} buttonText={selectedLinkOption === 'payment' ? languageMessages(language, "payNowAprroved") : languageMessages(language, "updateCard")} showRecurrent />}
                    </div>
                </div>
                <div style={success ? { display: 'block' } : { display: 'none' }}>
                    <PageConfirmation 
                        language = {language} 
                        firstname = {objectData?.firstName} 
                        amount = {objectData?.currency + ' ' + objectData?.symbol +  objectData?.amount} 
                        policy = {objectData?.policyNumber} 
                        type = {selectedLinkOption} 
                        card = {result?.digits} 
                        date = {objectData?.dues ? objectData?.dues[0].dueDate : ''}
                        isComplete = {status === 'success'}
                    />
                </div>
            </div>
            ) : (
                <Skeleton />
            )}
            <FormFlyware objectData={objectData} isModal={true} retry={retry} session={token} onCompleteFlywire={responseflywire} />
        </LinkOptionContext.Provider>
        </Layout>
    );
}

export default PaymentLink;