import React, { useEffect, useState } from "react";
import OleInputText from "./OleInputText";
import OlePhoneNumber from "./OlePhoneNumber";
import './styles/formData.css';
import OleCheckbox from "./OleCheckbox";
import OleDropdown from "./oleDropdown";
import { countries } from './resources/countries';
import { languageMessages } from './traductor/formData'
import { REACT_APP_FLYWIRE_CODE_US, REACT_APP_FLYWIRE_CODE_OTHER } from "config";

const FormPersonalData = ({ objectData, onClick, language, buttonText, showRecurrent }: { objectData: any, onClick: Function, language: string, buttonText: string, showRecurrent: boolean }) => {
    const [inputFirstnameValue, setInputFirstnameValue] = useState(objectData.payerFirstName || objectData.firstName || '');
    const [inputLastnameValue, setInputLastnameValue] = useState(objectData.payerLastName || objectData.lastName || '');
    const [inputEmailValue, setInputEmailValue] = useState(objectData.email || '');
    const [inputZipcodeValue, setInputZipcodeValue] = useState(objectData.zipCode || '');
    const [inputAddressValue, setInputAddressValue] = useState(objectData.address || '');
    const [inputRelationshipValue, setInputRelationshipValue] = useState(objectData.relationship || '');
    const [inputPhoneNumberValue, setInputPhoneNumberValue] = useState(objectData.phone || '');
    const [selectedPayingForPolicyValue, setSelectedPayingForPolicyValue] = useState('');
    const [selectedCountryValue, setSelectedCountryValue] = useState(objectData.country || '');
    const [selectedStateValue, setSelectedStateValue] = useState(objectData.state || '');
    const [selectedCityValue, setSelectedCityValue] = useState(objectData.city || '');
    const [optionsCountry, setOptionsCountry] = useState<{ value: string; label: string; }[]>([]);
    const [optionsState, setOptionsState] = useState<{ value: string; label: string; }[]>([]);
    const [optionsCity, setOptionsCity] = useState<{ value: string; label: string; }[]>([]);
    const [errorInputFirstname, setErrorInputFirstname] = useState('');
    const [errorInputLastname, setErrorInputLastname] = useState('');
    const [errorInputEmail, setErrorInputEmail] = useState('');
    const [errorInputZipcode, setErrorInputZipcode] = useState('');
    const [errorInputAddress, setErrorInputAddress] = useState('');
    const [errorInputRelationship, setErrorInputRelationship] = useState('');
    const [errorInputPhoneNumber, setErrorInputPhoneNumber] = useState('');
    const [errorSelectedPayingForPolicy, setErrorSelectedPayingForPolicy] = useState('');
    const [errorSelectedCountry, setErrorSelectedCountry] = useState('');
    const [errorSelectedState, setErrorSelectedState] = useState('');
    const [errorSelectedCity, setErrorSelectedCity] = useState('');
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        setSelectedPayingForPolicyValue("1");
        setOptionsCountry(countries?.map(country => ({ value: country.code.toUpperCase(), label: country.name })) || []);
        
        const states = getStates(objectData.country);
        setOptionsState(states.map(state => ({ value: state.code, label: state.name })));
        const cities = states.find(state => state.code === objectData.state)?.cities || [];
        setOptionsCity(cities.map(city => ({ value: city.code, label: camelize(city.name) })));
    }, [objectData]);

    const function_firstname = (value:any) => {
        setInputFirstnameValue(value);
        objectData.firstName = value;
    };
    const function_lastname = (value:any) => {
        setInputLastnameValue(value);
        objectData.lastName = value;
    };
    const function_email = (value:any) => {
        setInputEmailValue(value);
        objectData.email = value;
    }
    const function_phone = (value:any) => {
        setInputPhoneNumberValue(value);
        objectData.phone = value;
    }
    const function_zipcode = (value:any) => {
        setInputZipcodeValue(value);
        objectData.zipCode = value;
    }
    const function_billing_address = (value:any) => {
        setInputAddressValue(value);
        objectData.address = value;
    }
    const function_relationship = (value:any) => {
        setInputRelationshipValue(value);
        objectData.relationship = value;
    }

    const function_payingForPolicy = (value: any) => {
        setSelectedPayingForPolicyValue(value);
        objectData.payingForPolicy = value;
    }

    const getStates = (countryCurrent: string) => {
        const states = countries.find(country => country.code === countryCurrent)?.states || [];
        //const regions = countries.find(country => country.code === countryCurrent)?.regions || [];
        //const departments = countries.find(country => country.code === countryCurrent)?.departments || [];
        //const provinces = countries.find(country => country.code === countryCurrent)?.provinces || [];
        //const parishes = countries.find(country => country.code === countryCurrent)?.parishes || [];
        //const districts = countries.find(country => country.code === countryCurrent)?.districts || [];
        //const islands = countries.find(country => country.code === countryCurrent)?.islands || [];
        //const indigenousRegions = countries.find(country => country.code === countryCurrent)?.indigenousRegions || [];
        //const municipalities = countries.find(country => country.code === countryCurrent)?.municipalities || [];
        //return states.concat(regions, departments, provinces, parishes, islands, indigenousRegions, municipalities);
        return states;
    }

    const camelize = (str:string) => {
        return str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    }

    const function_country = (value: any) => {
        setSelectedCountryValue(value);
        objectData.country = value;
        objectData.recipientCode = REACT_APP_FLYWIRE_CODE_OTHER;
        if (value === 'US') {
            objectData.recipientCode = REACT_APP_FLYWIRE_CODE_US
        }
        const states = getStates(value);
        setOptionsState(states.map(state => ({ value: state.code, label: state.name })));
        setOptionsCity([]);
        if (states.length === 1) {
            objectData.state = states[0].code;
            setSelectedStateValue(states[0].code);
            const cities = states[0].cities || [];
            setOptionsCity(cities.map(city => ({ value: city.code, label: camelize(city.name) })));
            if (cities.length === 1) {
                objectData.city = cities[0].code;
                objectData.cityForFlywire = cities[0].name;
                setSelectedCityValue(cities[0].code);
            }
        }
    }
    
    const function_state = (value: any) => {
        setSelectedStateValue(value);
        objectData.state = value;
        const states = getStates(selectedCountryValue);
        const cities = states.find(state => state.code === value)?.cities || [];
        setOptionsCity(cities.map(city => ({ value: city.code, label: camelize(city.name) })));
        objectData.city = '';
        objectData.cityForFlywire = '';
        if (cities.length === 1) {
            objectData.city = cities[0].code;
            objectData.cityForFlywire = cities[0].name;
            setSelectedCityValue(cities[0].code);
        }
    }
    const function_city = (value: any) => {
        setSelectedCityValue(value);
        objectData.city = value;
        objectData.cityForFlywire = optionsCity.find(city => city.value === value)?.label || '';
    }
    const handleCheckboxChange = (id: any) => {
        if ((objectData.frequency === 'M' || objectData.frequency === 'Q') && !objectData.hasPaidPayment) {
            return;
        }
        objectData.recurrent = !checked;
        setChecked(!checked);
    };
    const handleInputErrorFirstname = (error:any) => {
        setErrorInputFirstname(error);
    };
    const handleInputErrorLastname = (error:any) => {
        setErrorInputLastname(error);
    };
    const handleInputErrorEmail = (error:any) => {
        setErrorInputEmail(error);
    };
    const handleInputPhoneNumber = (error:any) => {
        setErrorInputPhoneNumber(error);
    }
    const handleInputErrorZipcode = (error:any) => {
        setErrorInputZipcode(error);
    };
    const handleInputErrorAddress = (error:any) => {
        setErrorInputAddress(error);
    };
    const handleInputErrorRelationship = (error:any) => {
        setErrorInputRelationship(error);
    };
    const handleSelectedPayingForPolicy = (error:any) => {
        setErrorSelectedPayingForPolicy(error);
    }
    const handleSelectedCountry = (error:any) => {
        setErrorSelectedCountry(error);
    }
    const handleSelectedState = (error:any) => {
        setErrorSelectedState(error);
    }
    const handleSelectedCity = (error:any) => {
        setErrorSelectedCity(error);
    }
    const errorValidation = () => {
        return errorInputFirstname 
            || errorInputLastname
            || errorInputEmail
            || errorInputPhoneNumber
            || errorInputZipcode
            || errorInputAddress
            || errorSelectedPayingForPolicy
            || errorSelectedCountry
            || errorSelectedState
            || errorSelectedCity
    }

    const click_button = () => {
        if (errorValidation()) {
            return;
        }
        let cityTemp = optionsCity.find(city => city.value === selectedCityValue)?.label || '';
        objectData.cityForFlywire = camelize(cityTemp);
        if (optionsCity.length === 0 || objectData.cityForFlywire === '') {
            objectData.cityForFlywire = optionsState.find(state => state.value === selectedStateValue)?.label || '';
        }
        if (selectedPayingForPolicyValue === 'O' && errorInputRelationship) {
            return;
        }
        onClick();
    }

    return (
        <div className="container">
            <div>
            <span className="payerTitle" style={!errorValidation() ? {paddingBottom: '12px'} : {paddingBottom: '2px'}}>{languageMessages(language, "payerInformation")}</span>
            <span className="validation" style={!errorValidation() ? {display: 'none'} : {display: 'block'}}>{languageMessages(language, "validationFields")}</span>
            <div className="formData">
                    <div className="col col-4 col-sm-12">
                        <OleDropdown
                            label={languageMessages(language, "payingForPolicy")}
                            placeholder={languageMessages(language, "selectOption")}
                            value={selectedPayingForPolicyValue}
                            onChange={function_payingForPolicy}
                            options={languageMessages(language, "payingForPolicyOptions")}
                            style={{ width: '100%' }}
                            error={errorSelectedPayingForPolicy}
                            onError={handleSelectedPayingForPolicy}
                            language={language}
                        />
                    </div>
                    <div className="col col-4 col-sm-6">
                        <OleInputText
                            label={languageMessages(language, "firstName")}
                            format="name"
                            placeholder={languageMessages(language, "firstName")}
                            value={inputFirstnameValue}
                            onChange={function_firstname}
                            error={errorInputFirstname}
                            required
                            minLenght={2}
                            maxLength={100}
                            onError={handleInputErrorFirstname}
                            language={language}
                        />
                    </div>
                    <div className="col col-4 col-sm-6">
                        <OleInputText
                            label={languageMessages(language, "lastName")}
                            format="name"
                            placeholder={languageMessages(language, "lastName")}
                            value={inputLastnameValue}
                            onChange={function_lastname}
                            error={errorInputLastname}
                            required
                            minLenght={2}
                            maxLength={100}
                            onError={handleInputErrorLastname}
                            language={language}
                        />
                    </div>
                
                    <div className="col col-6 col-sm-12">
                        <OleInputText
                            label={languageMessages(language, "email")}
                            placeholder={languageMessages(language, "email")}
                            value={inputEmailValue}
                            onChange={function_email}
                            error={errorInputEmail}
                            required
                            format="email"
                            maxLength={100}
                            onError={handleInputErrorEmail}
                            language={language}
                        />
                    </div>
                    <div className="col col-6 col-sm-12">
                        <OlePhoneNumber
                            value={inputPhoneNumberValue}
                            language={language}
                            onChange={function_phone}
                            onError={handleInputPhoneNumber}
                        />
                    </div>

                    <div className="col col-4 col-sm-6">
                        <OleDropdown
                            label={languageMessages(language, "country")}
                            placeholder={languageMessages(language, "selectOption")}
                            value={selectedCountryValue}
                            onChange={function_country}
                            options={optionsCountry}
                            error={errorSelectedCountry}
                            style={{ width: '100%' }}
                            onError={handleSelectedCountry}
                            language={language}
                            filter={true}
                            textFilter={languageMessages(language, "searchCountry")}
                        />
                    </div>
                    <div className="col col-4 col-sm-6">
                        <OleDropdown
                            label={languageMessages(language, "state")}
                            placeholder={languageMessages(language, "selectOption")}
                            value={selectedStateValue}
                            onChange={function_state}
                            options={optionsState}
                            error={errorSelectedState}
                            style={{ width: '100%' }}
                            onError={handleSelectedState}
                            language={language}
                            filter={true}
                            textFilter={languageMessages(language, "searchState")}
                        />
                    </div>
                    <div className="col col-4 col-sm-6">
                        <OleDropdown
                            label={languageMessages(language, "city")}
                            placeholder={languageMessages(language, "selectOption")}
                            value={selectedCityValue}
                            onChange={function_city}
                            options={optionsCity}
                            error={errorSelectedCity}
                            style={{ width: '100%' }}
                            onError={handleSelectedCity}
                            language={language}
                            required={false}
                            filter={true}
                            textFilter={languageMessages(language, "searchCity")}
                            disabled={optionsCity.length === 0}
                        />
                    </div>

                    <div className="col col-8 col-sm-12">
                        <OleInputText
                            label={languageMessages(language, "billingAddress")}
                            placeholder={languageMessages(language, "billingAddress")}
                            value={inputAddressValue}
                            onChange={function_billing_address}
                            error={errorInputAddress}
                            required
                            onError={handleInputErrorAddress}
                            language={language}
                            maxLength={100}
                        />
                    </div>
                    <div className="col col-4 col-sm-6">
                        <OleInputText
                            label={languageMessages(language, "zipCode")}
                            placeholder={languageMessages(language, "zipCode")}
                            value={inputZipcodeValue}
                            onChange={function_zipcode}
                            error={errorInputZipcode}
                            required= {selectedCountryValue === 'US'}
                            maxLength={selectedCountryValue === 'US' ? 5 : 10}
                            format="number"
                            minLenght={selectedCountryValue === 'US' ? 5 : 0}
                            onError={handleInputErrorZipcode}
                            language={language}
                        />
                    </div>

                    {selectedPayingForPolicyValue === '3' && <div className="col col-12 col-sm-12">
                        <OleInputText
                            label={languageMessages(language, "relationship")}
                            placeholder={languageMessages(language, "relationship")}
                            value={inputRelationshipValue}
                            onChange={function_relationship}
                            onError={handleInputErrorRelationship}
                            language={language}
                            maxLength={50}
                        />
                    </div>}

                    <div className="col col-12" style = {showRecurrent ? {display: 'flex'} : {display: 'none'}}>
                        <OleCheckbox
                            onChange={handleCheckboxChange}
                            id={0}
                            checked={checked}
                            //disabled={objectData?.automaticApproval ? false : true}
                            text={languageMessages(language, "authorizeRecurrence")}
                        />
                    </div>
                
                    <div className="col col-12">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className={'button'} onClick={click_button}>
                                <div className='text'><div>{buttonText}</div></div>
                            </div>
                        </div>
                    </div>
                
            </div>
            </div>
        </div>
    );
}

export default FormPersonalData;