import React, { useEffect, useState } from 'react';
import './styles/oleInputText.css';
import {languageMessages} from './traductor/globalValidation';

const OleInputText = ({
    type = "text",
    format = "",
    required = false,
    maxLength = 100,
    minLenght = 0,
    label, 
    placeholder, 
    value, 
    onChange = () => {}, 
    style, 
    error = '',
    onError = () => {},
    language = 'en',
    className = '',
}: {
    type?: string;
    format?: string;
    required?: boolean;
    maxLength?: number;
    minLenght?: number;
    label?: string;
    placeholder?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    style?: React.CSSProperties;
    error?: string;
    onError?: (error: string) => void;
    language?: string;
    className?: string;
}) => {
  const [valueLocal, setValueLocal] = useState(value || '');
  const [isFocused, setIsFocused] = useState(false);
  const [errorNative, setErrorNative] = useState('');

  const handleFocus = () => {
    validateInput(valueLocal);
    setIsFocused(true)
  };
  const handleBlur = () => {
    validateInput(valueLocal);
    setIsFocused(value !== '')
  };
  
  const validateInput = (inputValue: string) => {
    let validationError = '';
    if (format === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) {
        validationError = languageMessages(language, "emailInvalid");
    }
    if (format === 'phone' && !/^\d{10}$/.test(inputValue)) {
        validationError = languageMessages(language, "phoneInvalid");
    }
    if (format === 'number' && !/^\d*$/.test(inputValue)) {
        validationError = languageMessages(language, "invalidCaracters");
    }
    if (format === 'name') {
        const regex = /^[a-zA-Z' ]*$/;
        if (!regex.test(inputValue)) {
          validationError = languageMessages(language, "invalidCaracters");
        }
    }
    if (inputValue.length < minLenght) {
        validationError = languageMessages(language, "minCaracters");
    }
    if (inputValue === '' && required) {
      validationError = languageMessages(language, "required");
    }

    return validationError;
  };

  useEffect(() => {
    const initialValidationError = validateInput(value || '');
    setErrorNative(initialValidationError);
    onError(initialValidationError);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const initialValidationError = validateInput(value || '');
    setErrorNative(initialValidationError);
    onError(initialValidationError);
    // eslint-disable-next-line
  }, [value, required, minLenght]);

  const handleChange = (e: { target: { value: any; }; }) => {
    let inputValue = e?.target?.value;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    const validationError = validateInput(inputValue);
    setErrorNative(validationError);
    onError(validationError);
    setValueLocal(inputValue);
    if (onChange) onChange(inputValue);
  };

  return (
    <div className={`custom-input-wrapper ${isFocused || value ? 'focused' : ''}`}>
      {label && <label className="custom-input-label">{!isFocused && value === '' ? '' : label}</label>}
      <input
        type={type}
        className={`custom-input ${errorNative ? 'error' : ''} `.concat(className)}
        placeholder={isFocused ? '' : placeholder}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        style={style}
      />
      {errorNative && <span className="error-message">{errorNative}</span>}
    </div>
  );
};

export default OleInputText;
